import { useState } from "react";
import { useAuth } from "../store/auth";
import { Link } from "react-router-dom";

const Calendar = () => {
  const calendardata = [
    {
      label: "Conference Room",
      value: "conference-room",
      src: "https://calendar.google.com/calendar/embed?src=d623ba6cded4ad25c01be956913ade73d57decc21fd6d07b16c3dff36406cc5d%40group.calendar.google.com&ctz=Asia%2FKolkata",
    },
    {
      label: "Bhaktivedanta Hall",
      value: "bhaktivedanta-hall",
      src: "https://calendar.google.com/calendar/embed?src=77a6194605e843ee7cf85475c9450f3aec8d787615a36dceb67f0ee94168bd49%40group.calendar.google.com&ctz=Asia%2FKolkata",
    },
    {
      label: "Bhaktisiddhanta Hall",
      value: "bhaktisiddhanta-hall",
      src: "https://calendar.google.com/calendar/embed?src=edaa6e1034bf94afd350955644ac544222cd77060da725bcf0219e8229c1bb7c%40group.calendar.google.com&ctz=Asia%2FKolkata",
    },
    {
      label: "Gopalji Hall",
      value: "gopalji-hall",
      src: "https://calendar.google.com/calendar/embed?src=391be59d0466d0ccf3606d3086a9b2368621a5b8ccae847f2e151e4da058a134%40group.calendar.google.com&ctz=Asia%2FKolkata",
    },
    {
      label: "BACE Class Rooms (Nitai room included)",
      value: "bace-class-rooms",
      src: "https://calendar.google.com/calendar/embed?src=da821b950210929e172a782be2010657bb0bfb48784777f6440e914c0b247282%40group.calendar.google.com&ctz=Asia%2FKolkata",
    },
    {
      label: "Laxmi Narayan Hall",
      value: "laxmi-narayan-hall",
      src: "https://calendar.google.com/calendar/embed?src=5340a928e6268fb56f9aa0399cd7ee720f2a7f9e0630528fcfe398c5cffa73a1%40group.calendar.google.com&ctz=Asia%2FKolkata",
    },
    {
      label: "Bhaktivinoda Hall",
      value: "bhaktivinoda-hall",
      src: "https://calendar.google.com/calendar/embed?src=8aae3f6002bac136d8c605cd3905d2bba586155cfaa15ec0b4bcaf4fbb1dbc55%40group.calendar.google.com&ctz=Asia%2FKolkata",
    },
  ];

  const [activeTab, setActiveTab] = useState(calendardata[0].value);
  const { isLoggedIn } = useAuth();

  return (
    <div className="bg-orange-100 pt-[6rem] p-4 md:p-10 mt-4 md:mt-16">
      <div className="flex flex-col md:flex-row">
        {/* Sidebar with tabs */}
        <div className="w-full md:w-1/4 md:pt-16 md:pl-4 mb-4 md:mb-0">
          {calendardata.map((item) => (
            <button
              key={item.value}
              className={`w-full py-2 px-4 text-left focus:outline-none ${
                activeTab === item.value
                  ? "bg-orange-500 text-white"
                  : "bg-gray-200 text-gray-800"
              } hover:bg-orange-500 hover:text-white transition-colors duration-300 rounded-md mb-2`}
              onClick={() => setActiveTab(item.value)}
            >
              {item.label}
            </button>
          ))}
          <div className="text-center mb-4 mt-8">
            {isLoggedIn ? (
              <>
                <h1 className="mb-2">To Book ? click below</h1>
                <Link to="/bookingform">
                  <button className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-300">
                    Request Booking Form
                  </button>
                </Link>
              </>
            ) : (
              <Link to="/login">
                <button className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-300">
                  Request Booking Form
                </button>
              </Link>
            )}
          </div>
        </div>

        {/* Calendar display */}
        <div className="w-full md:w-3/4">
          <div className="relative pb-[56.25%] h-0 overflow-hidden rounded-lg">
            <iframe
              src={calendardata.find((item) => item.value === activeTab)?.src}
              className="absolute md:top-10 md:left-[8rem] md:w-[80%] md:h-full w-full h-full"
              frameBorder="0"
              scrolling="no"
              title={activeTab}
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
