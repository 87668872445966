import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../store/auth";
import axios from "axios";
import { toast } from "react-toastify";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import API_BASE_URL from "../utils/api";

function Login() {
  const { storeToken } = useAuth();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const formValidation = () => {
    const errors = {};
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({});

    if (!formData.email || !formData.password) {
      toast.error("Email and Password are required");
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      toast.error("Email is invalid");
    }

    const validationErrors = formValidation();

    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
    } else {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        // console.log(`booking: ${API_BASE_URL}/api/users/login`);

        const response = await axios.post(
          `${API_BASE_URL}/api/users/login`,
          formData,
          config
        );

        if (response.status === 200) {
          const { token } = response.data;
          console.log(response.data)
          storeToken(token);
          localStorage.setItem("token", token);
          toast.success("Login successful");
          navigate("/");
        } else {
          toast.error("Login failed");
        }
        setFormData({
          email: "",
          password: "",
        });
      } catch (error) {
        if (error.response && error.response.data) {
          toast.error(error.response.data.error || "Login failed");
        } else {
          console.error("Login failed:", error.message);
          toast.error("Something went wrong, please try again.");
        }
        console.log(error.response ? error.response.data.error : error.message);
      }
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });

    setError({ ...error, [name]: "" });
  };

  return (
    <div className="flex items-center justify-center h-screen bg-orange-100">
      <form
        onSubmit={handleSubmit}
        className="bg-white w-[500px] p-8 shadow-md"
      >
         <h1 className="text-gray-700 text-2xl font-bold mb-8">
              Login Page
         </h1>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email:
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInput}
            className={`border ${
              error.email ? "border-red-500" : "border-gray-300"
            } px-4 py-1 w-[100%]  focus:outline-none focus:border-black`}
          />
          <div className="text-red-500 my-2"> {error.email} </div>
        </div>

        <div className="mb-4 relative">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="password"
          >
            Password:
          </label>
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            value={formData.password}
            onChange={handleInput}
            className={`border ${
              error.password ? "border-red-500" : "border-gray-300"
            } px-4 py-1 w-[100%]  focus:outline-none focus:border-black`}
          />
          <div className="absolute inset-y-0 right-0 pt-7 flex items-center text-lg leading-5">
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="focus:outline-none w-8 h-8"
            >
              {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
            </button>
          </div>
          <div className="text-red-500 my-2"> {error.password} </div>
        </div>

        <button
          type="submit"
          className="bg-black text-white py-2 px-8 my-3 rounded-full hover:bg-gray-800 focus:outline-none focus:bg-gray-800"
        >
          Submit
        </button>

        <div className="pt-5 text-center">
          <div className="font-semibold">
            <NavLink to="/forgotpassword" className="text-gray-400">
              Forgot password?
            </NavLink>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;
