import React, { useState, useEffect } from "react";
import { CiCircleRemove } from "react-icons/ci";
import { useAuth } from "../store/auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ImSpinner } from "react-icons/im";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import API_BASE_URL from "../utils/api";

const Profile = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    department: "",
    phoneNo: "",
    role: "",
  });
  const [bookings, setBookings] = useState([]);

  const { authorizationToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Load user details and profile image from browser storage
    const storedUserDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (storedUserDetails) {
      setUserDetails(storedUserDetails);
    }
    const storedImage = localStorage.getItem("selectedImage");
    if (storedImage) {
      setSelectedImage(storedImage);
    }
  }, []);

  const fetchUserProfile = async () => {
    try {
      const config = {
        headers: {
          Authorization: authorizationToken,
        },
      };
      const response = await axios.get(
        `${API_BASE_URL}/api/users/profile`,
        config
      );

      if (response.status === 200) {
        setUserDetails(response.data.user);
        console.log(response.data.user);
        
        setBookings(response.data.bookings);
      }
    } catch (error) {
      console.log("Error fetching user profile");
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const handleImageSelect = (e) => {
    const file = e.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
    localStorage.setItem("selectedImage", URL.createObjectURL(file));
  };

  const removeImage = () => {
    setSelectedImage(null);
    localStorage.removeItem("selectedImage");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    localStorage.setItem("userDetails", JSON.stringify(userDetails));
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    navigate(`/profileEdit`);
  };

  const deleteBooking = async (id) => {
    setIsDeleting(true);
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete this item?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const config = {
                headers: {
                  Authorization: authorizationToken,
                },
              };
              await axios.delete(
                `${API_BASE_URL}/api/users/profile/delete/${id}`,
                config
              );
              setBookings((prevBookings) =>
                prevBookings.filter((booking) => booking.id !== id)
              );
            } catch (error) {
              console.log("Error deleting booking", error);
            } finally {
              setIsConfirmLoading(false); // Clear loading state
              setIsDeleting(false); // Clear deleting state
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            setIsDeleting(false);
          },
        },
      ],
    });
  };

  return (
    <div className="flex flex-col items-center h-screen bg-orange-100 pt-[6rem] ">
      <div className="w-full max-w-2xl bg-white p-8 shadow-md rounded-md ">
        <div className="flex flex-col w-full items-center ">
          {/* Profile Image */}
          <div
            className="relative mb-4 w-36 h-36"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {selectedImage ? (
              <>
                <img
                  src={selectedImage}
                  alt="Profile"
                  className="w-full h-full object-cover rounded-full"
                />
                {isHovered && (
                  <button
                    onClick={removeImage}
                    className="absolute bottom-0 right-0 bg-red-500 text-white rounded-full p-1"
                  >
                    <CiCircleRemove />
                  </button>
                )}
              </>
            ) : (
              <>
                <div className="w-full h-full flex justify-center items-center bg-gray-200 rounded-full">
                  <span className="text-gray-400">Upload</span>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageSelect}
                  className="absolute inset-0 opacity-0 cursor-pointer"
                />
              </>
            )}
          </div>

          {/* User Name */}
          {/* Form Inputs */}
          <div className="flex flex-wrap -mx-2 pt-10 w-full">
            <div className="w-1/2 px-2 mb-4">
              <input
                type="text"
                name="name"
                value={userDetails.name}
                onChange={handleInputChange}
                className="border-b mb-4 px-2 py-1 w-full focus:outline-none focus:border-blue-500 text-center"
                placeholder="name"
              />
            </div>
            <div className="w-1/2 px-2 mb-4">
              <input
                type="email"
                name="email"
                value={userDetails.email}
                onChange={handleInputChange}
                className="border-b mb-4 px-2 py-1 w-full focus:outline-none focus:border-blue-500 text-center"
                placeholder="Email"
              />
            </div>
            <div className="w-1/2 px-2 mb-4">
              <input
                type="text"
                name="department"
                value={userDetails.department}
                onChange={handleInputChange}
                className="border-b mb-4 px-2 py-1 w-full focus:outline-none focus:border-blue-500 text-center"
                placeholder="Department"
              />
            </div>
            <div className="w-1/2 px-2 mb-4">
              <input
                type="text"
                name="phoneNo"
                value={userDetails.phoneNo}
                onChange={handleInputChange}
                className="border-b mb-4 px-2 py-1 w-full focus:outline-none focus:border-blue-500 text-center"
                placeholder="Phone Number"
              />
            </div>
          </div>
          <div className="w-1/2 px-2 mb-4">
            <input
              type="text"
              name="role"
              value={userDetails.role}
              onChange={handleInputChange}
              className="border-b mb-4 px-2 py-1 w-full focus:outline-none focus:border-blue-500 text-center"
              placeholder="Role"
            />
          </div>

          {/* Edit Button */}
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none"
            onClick={handleEditClick}
          >
            Edit
          </button>
        </div>
      </div>
      {/* Bookings Table */}

      {isDeleting ? (
        <div className="flex justify-center items-center h-screen">
          <div className="spinner-border text-primary" role="status">
            <ImSpinner className="w-10 h-10 animate-spin" />
          </div>
        </div>
      ) : (
        <div className="w-full bg-orange-100 p-4 pb-[6rem] mt-[rem] shadow-md rounded-md">
          <div className="overflow-x-auto">
            <h1 className=" text-3xl text-center py-6">My Booking</h1>
            <table className="min-w-full bg-orange-100 border border-gray-800 rounded-lg shadow-md">
              <thead className="bg-orange-100">
                <tr>
                  <th className="py-2 px-2 border-b border-gray-800">Date</th>
                  <th className="py-2 px-2 border-b border-gray-800">
                    Start Time
                  </th>
                  <th className="py-2 px-2 border-b border-gray-800">
                    End Time
                  </th>
                  <th className="py-2 px-2 border-b border-gray-800">
                    Hall Name
                  </th>
                  <th className="py-2 px-2 border-b border-gray-800">
                    Purpose
                  </th>
                  <th className="py-2 px-2 border-b border-gray-800">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {bookings.map((booking) => (
                  <tr key={booking.id}>
                    <td className="py-2 px-6 border-b border-gray-500">
                      {new Date(booking.date).toLocaleDateString()}
                    </td>
                    <td className="py-2 px-[3rem] border-b border-gray-500">
                      {new Date(booking.startTime).toLocaleTimeString()}
                    </td>
                    <td className="py-2 px-[3rem] border-b border-gray-500">
                      {new Date(booking.endTime).toLocaleTimeString()}
                    </td>
                    <td className="py-2 px-[3rem] border-b border-gray-500">
                      {booking.hallName}
                    </td>
                    <td className="py-2 px-[3rem] border-b border-gray-500">
                      {booking.purpose}
                    </td>
                    <td className="py-2 px-[3rem] border-b border-gray-500">
                      <button
                        onClick={() => deleteBooking(booking.id)}
                        className="bg-red-500 text-white py-1 px-2 rounded-md hover:bg-red-600 focus:outline-none"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
